import React from 'react';
import {Link} from "gatsby";
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
    display: inline-block;
    background-color: ${p => p.backgroundColor};
    box-shadow: none;
    color: ${p => p.foregroundColor};
    font-size:20px;
    border: 0;
    padding: 15px 30px;
    margin: 15px 0;
    text-decoration: none;
    background-image: none;
    text-shadow: none;
    text-transform: uppercase;
    border-radius: 3px;
    width: ${p => p.isFullwidth ? '100%' : 'auto'};
    cursor: pointer;
    transition: 0.4s;
    &:hover {
        box-shadow: 0 0 0 2px ${p => p.backgroundColor};
    }
`;

const Button = ({ isLink, isFullwidth, children, backgroundColor, foregroundColor, to }) => (
    <StyledButton 
        as={isLink && Link}
        to={to}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        isFullwidth={isFullwidth}
        isLink={isLink}
    >
        {children}
    </StyledButton>
);

Button.propTypes = {
    backgroundColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    isLink: PropTypes.bool,
    isFullwidth: PropTypes.bool,
    to: PropTypes.string,
};

Button.defaultProps = {
    backgroundColor: 'var(--accent-orange-300)',
    foregroundColor: 'white',
    isLink: false,
    isFullwidth: false,
    to: null,
};

export default Button;
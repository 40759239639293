import React from "react"
import styled from 'styled-components'
import Button from '../Button/button'
import Img from "gatsby-image"

const TxtImgArea = styled.div`
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding-bottom: 3rem;
    display: block;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 980px) {
        display: flex;
        flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    }
`;

const TxtContainer = styled.div`
    width: 100%;
    @media only screen and (min-width: 980px) {
        width: 47.25%;
    }
    .txtBody a {
        color: var(--accent-orange-500);
        text-decoration: none;
    }
    .txtBody a:hover {
        text-decoration: underline;
    }
`;

const Heading = styled.h1`
    margin-top: ${props => props.subheading ? 0 : "23px"};
    color: ${props => props.headingColor === "accent" ? "var(--accent-turquoise-500)" : "inherit"};
`

const Subheading = styled.h2`
    margin: 0;
    font-size:18px;
    color: var(--neutral-600);
`

const ImgContainer = styled.div`
    width: 100%;
    text-align: center;
    @media only screen and (min-width: 980px) {
        width: 47.25%;
    }
`;

export default function TxtImg(props) {
    return (
        <TxtImgArea reverse = {props.reverse}>
            <TxtContainer>
                {
                    props.subheading ? (
                        <Subheading>{props.subheading}</Subheading>
                    ) : (<></>)
                }
                <Heading headingColor={props.headingColor} subheading={props.subheading}>{props.heading}</Heading>
                <div className="underline" style={{display: props.underline ? "block" : "none"}}></div>
                <div className="txtBody">
                    {props.children}
                </div>
                {
                    props.linkTo ? (
                        <Button to={props.linkTo} isLink>{props.linkName}</Button>
                    ) : (<></>)
                }
            </TxtContainer>
            <ImgContainer>
                <Img fluid={props.imgSrc} alt={props.imgAlt} />
            </ImgContainer>
        </TxtImgArea>
    )
}
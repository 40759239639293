import React from "react"
import styled from 'styled-components';

const Panel = styled.div`
    background-image: linear-gradient(105deg, hsla(208, 70%, 51%, 0) 55%, var(--neutral-300) 0%), url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 50px 0;

    @media only screen and (min-width: 800px) {
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        padding: 100px 0;
    }
`;

const Box = styled.div`
    grid-column-start: 2;
    padding: 80px 10%;
    background-color: var(--neutral-200);
    max-width: 700px;
    margin: 20px 0;
    p {
    margin-bottom: 0;
    color: var(--neutral-800);
    font-size: 22px;
    margin-block-start: 0em;
    }
    span {
        text-transform: uppercase;
        color: var(--accent-turquoise-500);
    }
`;

export default function FullwidthBox(props) {
    return (
        <Panel image={props.image}>
            <Box>
                <p>{props.children}</p>
            </Box>
        </Panel>
    )
}
import React from "react"
import FullwidthBox from "../../components/fullwidthBox/fullwidthBox"
import Layout from "../../components/layout/layout"
import styled from 'styled-components'
import Title from "../../components/title/title"
import TxtImg from "../../components/txtImg/txtImg"
import { graphql } from "gatsby"
import Sun from "../../images/shutterstock_sun.jpg"
import { Helmet } from "react-helmet"

const BgWhite = styled.section`
  background-color: var(--neutral-100);
  padding-bottom: 5rem;
`;

export default function Recovery({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Solar Energy Recovery | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <Title
        title="Thermal energy recovery"
        subtitle="A Ground Source Heat Pump transfers heat from our thermal energy store, the Earth Energy Bank, for space heating and hot water all year round."
        gradient={true}
      />
      <BgWhite>
        <TxtImg heading="Heat pump" headingColor="accent" imgSrc={data.flexoTherm.childImageSharp.fluid} imgAlt="FlexoTherm heat pump">
          <p>A heat pump, simply put, is a device that uses a minimal amount of energy to move heat from one location to another. They are designed to convert large volumes of low-grade heat, into smaller volumes at a higher temperature, transferring thermal energy in the opposite direction of the spontaneous heat flow. Within the Caplin Solar thermal system a ground source heat pump is used to extract low-grade heat from the Earth Energy Bank and bring it up to the temperatures required for space heating and hot water.</p>
          <p>In conventional applications (ground loop, deep bore), ground sources heat pumps are generally used to extract naturally existing heat from the ground, often reducing the temperature of the surrounding earth to near freezing. As the earth mass cools the efficiency of the heat pump is reduced. When a ground source heat pump is paired with the Earth Energy Bank this problem is resolved. As the EEB is “charged” with thermal energy, the ground temperature rises above ambient. Consequently, when heat is extracted, the minimum temperature stays significantly higher, increasing the efficiency of the heat pump.</p>
        </TxtImg>
        <div className="stdWidth" style={{marginTop: "-3.5rem"}}>
          <p>The heat pump is powered by electrical energy from PV-T panels. These heat pumps are relatively compact, with the smallest range similar in size to a standard combi-boiler. They are practically silent and give off minimal vibration, making them incredibly easy to incorporate into a utility room. It is also worth noting that heat pumps are eligible for the Renewable Heating Incentive (RHI) in individual homes and multi-occupancy commercial developments.</p>
        </div>
      </BgWhite>
      <FullwidthBox image={Sun}>
      For the first time <span>Ground Source Heat Pumps</span> can be deployed effectively in dense housing developments. Because the ground collectors sit within the building footprint, the whole system is contained within the structure that it serves.
      </FullwidthBox>
    </Layout>
  )
}

export const query = graphql`
  query {
    flexoTherm: file(relativePath: {eq: "images/flexoTHERM.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
import React from "react"
import styled from 'styled-components';

const TitleArea = styled.section`
    padding-top: 20px;
    padding-bottom: 0em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    background-color: var(--neutral-300);
`;

const Container = styled.div`
    width: 80%;
    max-width: 800px;
    margin: auto;
    p{
        margin-block-start: 0.8em;
        font-size: 18px;
    }
`;

const TitleText = styled.h1`
  font-size: 30px;
  text-align: center;
  color: var(--accent-turquoise-500);
  margin-block-end: 0em;
`;

const BgGradientWhite = styled.section`
  background-image: linear-gradient(1.5deg, var(--neutral-100) 14%, var(--neutral-300) 14.2%)!important;
  padding-bottom: 5em;
  margin-bottom: -1em;

  @media only screen and (min-width: 900px) {
      margin-bottom: 0;
  }
  `;

export default function Title(props) {
    if (props.gradient) {
        return (
            <BgGradientWhite gradient={props.gradient}>
                <TitleArea>
                    <Container>
                        <TitleText>{props.title}</TitleText>
                        <p>{props.subtitle}</p>
                    </Container>
                </TitleArea>
            </BgGradientWhite>
        )
    } else {
        return (
            <TitleArea>
                <Container>
                    <TitleText>{props.title}</TitleText>
                    <p>{props.subtitle}</p>
                </Container>
            </TitleArea>
        )
    }
}